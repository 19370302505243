import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import SneakerImage from "./../assets/images/sneaker.png";

export default function LoggedIn() {
  const { user, logout } = useKindeAuth();

  return (
    <div className="c-body-alt">
      <header>
        <nav className="nav container">
          <h1 className="c-logo">Goatly Oregon</h1>
          <div className="profile-blob">
            <div className="avatar">
              {user?.given_name?.[0]}
              {user?.family_name?.[1]}
            </div>
            <div>
              <p className="text-heading-2">
                {user.given_name} {user.family_name}
              </p>
              <button className="text-subtle" onClick={logout}>
                Sign out
              </button>
            </div>
          </div>
        </nav>
      </header>

      <main>
        <div className="container">
          <img className="c-sneaker" src={SneakerImage} />
        </div>
      </main>
    </div>
  );
}
